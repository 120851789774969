export const environment = {
    production: false,
    SERVER_URL: 'https://osat.tsms.gov.go.tz:9080',
    AUTH_URL: 'https://osat.tsms.gov.go.tz:9080',
    GRAPHQL_URL: 'https://osat.tsms.gov.go.tz:9080',
    CLIENT_ID: 'shared-helpdesk',
    CLIENT_SECRET: 'shared@helpdesk',
    SESSION_TIME_OUT_ON_IDLE: 600
};

// export const environment = {
//     production: false,
//     SERVER_URL: 'http://102.223.8.54:9080',
//     AUTH_URL: 'http://102.223.8.54:9080',
//     GRAPHQL_URL: 'http://102.223.8.54:9080',
//     CLIENT_ID: 'shared-helpdesk',
//     CLIENT_SECRET: 'shared@helpdesk',
//     SESSION_TIME_OUT_ON_IDLE: 600
// };


// === TEST ENVIRONMENT ===== 
// export const environment = {
//     production: false,
//     SERVER_URL: 'http://172.17.17.7:8805',
//     AUTH_URL: 'http://172.17.17.7:8805',
//     GRAPHQL_URL: 'http://172.17.17.7:8805',
//     CLIENT_ID: 'shared-helpdesk',
//     CLIENT_SECRET: 'shared@helpdesk',
//     SESSION_TIME_OUT_ON_IDLE: 600
// };

// export const environment = {
//     production: false,
//     SERVER_URL: 'http://102.223.11.195:8805',
//     AUTH_URL: 'http://102.223.11.195:8805',
//     GRAPHQL_URL: 'http://102.223.11.195:8805',
//     CLIENT_ID: 'shared-helpdesk',
//     CLIENT_SECRET: 'shared@helpdesk',
//     SESSION_TIME_OUT_ON_IDLE: 600
// };